import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

import routes from 'components/routes';
import withRouter from 'components/Wrappers/withRouter';
import IEDisabled from 'views/NotAvailable/IEDisabled';
import IdleTimer from 'components/IdleTimer/component';
import { SENTRY, scrollTo } from 'components/modules/_misc';
import { DEFAULT } from 'components/modules/app';
import { INIT_INFO_ROUTES, nextAppPage } from 'components/modules/nav';
import { UserActions } from 'reducers/user';
import { GlobalActions } from 'reducers/global';
import MaintenancePage from 'views/NotAvailable/Maintenance';
import API from 'components/api';
import { cloneDeep } from 'components/modules/_misc';

const isIE = /*@cc_on!@*/ !!document.documentMode;

class AppWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			support_app_id: '',
			maintenance_msg: '',
			forced_page: ''
		};

		SENTRY.set_tag('triggering_app_id', '');
		SENTRY.set_tag('bypass_code', '');
		SENTRY.set_tag('coupon_code', '');
		SENTRY.set_tag('address_string', '');
	}

	componentDidMount() {
		const { location, email, setCurrentPage, loading_data } = this.props,
			{ search } = location;

		setCurrentPage(location.pathname);
		this.addAdaScript();

		if (search.includes('load_app_id=')) {
			//LOAD_APP_ID LOGIC - Used by support to load a student's app and have ability to edit/update
			let _app_id = (search.split('load_app_id=')[1] || '').toLowerCase();
			this.setState({ support_app_id: _app_id });
		} else if (search.includes('bypass_term=')) {
			this.onSetBypassCode(search.split('bypass_term=')[1]);
		} else {
			const forced_page = this.forceNavigation(true);
			if (forced_page) {
				this.setState({ forced_page: forced_page });
			} else {
				if (email) this.onLogin();
				(loading_data || []).forEach(key => this.onLoadMissingData(key));
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
				apps,
				app_is_saving,
				app_id,
				email,
				location,
				campus_essay_prompts,
				setUseTempAppRec,
				setCurrentPage,
				loading_data,
				captureError,
				navigate,
				deleteApp
			} = this.props,
			{ support_app_id, forced_page } = this.state;

		if (location.pathname !== prevProps.location.pathname) {
			const forced_page_detected = this.forceNavigation();
			this.setState({ forced_page: forced_page_detected });

			if (!forced_page_detected) {
				setCurrentPage(location.pathname);

				const inInitInfo = INIT_INFO_ROUTES.includes(location.pathname),
					wasInInitInfo = INIT_INFO_ROUTES.includes(prevProps.location.pathname);

				if (inInitInfo !== wasInInitInfo) setUseTempAppRec(inInitInfo, !inInitInfo && wasInInitInfo);

				setTimeout(() => {
					scrollTo();
				}, 50);
			}

			if (forced_page) {
				if (email) this.onLogin();
				(loading_data || []).forEach(key => this.onLoadMissingData(key));
			}
		}

		if (forced_page) {
			if (!prevState.forced_page) navigate(forced_page);
		} else {
			if (app_is_saving && !prevProps.app_is_saving) this.onAppIsSaving();

			if (support_app_id) {
				if (!prevState.support_app_id)
					this.getAPI()
						.getApplication(support_app_id)
						.then(this.onFetchSupportApps)
						.catch(ex => captureError(ex));

				if (email && app_id && !(prevProps.email && prevProps.app_id)) this.onSupportLogin();
			} else if (email && !prevProps.email) {
				this.onLogin();
			} else if (!email && prevProps.email) {
				this.getAPI()
					.logout()
					.catch(ex => {
						// this would have no impact
					});
			}

			const newly_loading_data = loading_data.filter(key => !prevProps.loading_data.includes(key));
			(newly_loading_data || []).forEach(key => this.onLoadMissingData(key));

			// TODO: make a reducer out of this
			if (
				window.innerWidth < 993 &&
				location.pathname !== prevProps.location.pathname &&
				document.documentElement.className.includes('nav-open')
			) {
				document.documentElement.classList.toggle('nav-open');
				document.querySelectorAll('#bodyClick').forEach(e => e.remove());
			}
		}

		const new_app_ids = Object.keys(apps).filter(_app_id => !Object.keys(prevProps.apps).includes(_app_id)),
			essay_prompts_loaded = campus_essay_prompts.length > prevProps.campus_essay_prompts.length;

		if (
			(!!new_app_ids.length && !!campus_essay_prompts.length) ||
			(essay_prompts_loaded && !!Object.keys(apps).length)
		) {
			this.saveEssayRequirement();
		}

		// tms:  something went wrong splitting up a DA form; delete the apps that were created but not submitted
		new_app_ids
			.filter(_app_id => {
				const { app_type, date_submitted, json_obj } = apps[_app_id],
					{ id: campus_id } = json_obj.initial_information.chosen_campus || {};

				return app_type === 'DA' && !!campus_id && !date_submitted;
			})
			.forEach(_app_id => {
				deleteApp(_app_id);

				this.getAPI()
					.deleteApplication(_app_id)
					.catch(ex => captureError(ex));
			});
	}

	getEssayRequirement = (app_type, campus_id) => {
		let { campus_essay_prompts } = this.props;

		if (['FM_D', 'FM_I', 'TR_D', 'TR_I'].includes(app_type)) {
			let type_for_prompt = `${app_type}`.toLowerCase();
			type_for_prompt = type_for_prompt.replace('re_', 'tr_').replace('ret_', 'tr_');

			const udsPrompt = campus_essay_prompts.find(
					rec =>
						rec.field_campuses.includes(campus_id) &&
						rec.field_global_essay_prompt_value === `undergrad-${type_for_prompt}`
				),
				{ field_essay_display: _req } = udsPrompt || {};

			return `${_req}`;
		}

		return '';
	};

	saveEssayRequirement = () => {
		const { apps, updateApp, captureError } = this.props;

		Object.keys(apps).forEach(_app_id => {
			let _app = cloneDeep(apps[_app_id].json_obj),
				{ application_modifier: app_type, initial_information } = _app,
				{ id: campus_id } = initial_information?.chosen_campus || {};

			if (!!campus_id && !apps[_app_id].date_submitted) {
				const _req = this.getEssayRequirement(app_type, campus_id);

				if (!!_req && _req !== _app.essay.requirement) {
					if (_req === 'none') _app.essay = cloneDeep(DEFAULT.APP_JSON.essay);
					_app.essay.requirement = _req;

					updateApp({ essay: _app.essay }, _app_id);

					this.getAPI()
						.saveApplication(_app_id, JSON.stringify(_app))
						.then(resp => {
							if (resp?.status !== 'success') throw new Error(resp?.message || '');
						})
						.catch(ex => {
							captureError(
								`error calling saveApplication on pre-submit checks (${_app_id}): ${ex.message}`
							);
						});
				}
			}
		});
	};

	forceNavigation = mounting => {
		let { location, email, app_id, force_pwd_reset, force_email_verification } = this.props,
			{ support_student_id } = this.state,
			{ logging_out } = location.state || {},
			current_route = routes.find(r => r.path === location.pathname),
			tgt_page = '';

		if (current_route) {
			if (email) {
				if (
					(current_route.requireAnonymous && !logging_out) ||
					(!app_id && current_route.requireAppId) ||
					(mounting && current_route.initInfoPage)
				) {
					tgt_page = '/my-account';
				}
			} else if (current_route.requireAppId || current_route.requireAuthentication) {
				tgt_page = '/sign-in';
			}
		} else {
			tgt_page = email ? '/my-account' : '/';
		}

		if (location.pathname !== '/reset-password-form') {
			if (force_pwd_reset) {
				tgt_page = '/reset-password-form';
			} else if (force_email_verification && location.pathname !== '/verify' && !logging_out) {
				tgt_page = '/verify';
			}
		}

		if (support_student_id) tgt_page = '';

		return tgt_page;
	};

	getAPI = () => {
		const { support_student_id, campus_admin } = this.props;
		return new API('', support_student_id, campus_admin);
	};

	addAdaScript = () => {
		const existing_elem = document.getElementById('__ada');

		if (!existing_elem) {
			let script = document.createElement('script');
			script.src = 'https://static.ada.support/embed2.js';
			script.id = '__ada';
			script.setAttribute('data-handle', 'uwhelp');
			//script.onload = function () {};

			try {
				document.head.appendChild(script);
			} catch (e) {
				/* tms:
					don't pass exception up to global error-handling; if Ada won't load,
					there's nothing we can do about it, and the app will still work
				*/
				Sentry.captureException(e);
			}
		}
	};

	onLogin = () => {
		let { location, setApps, getDocuments, finishedLoading, captureError, onCheckForStaleData } = this.props,
			second_call = false;

		onCheckForStaleData(location.pathname);

		this.getAPI().logIpAddress();

		this.getAPI()
			.getAllApplications()
			.then(resp => {
				setApps(resp.rows || []);

				if (second_call) {
					finishedLoading();
				} else {
					second_call = true;
				}
			})
			.catch(ex => captureError(ex));

		this.getAPI()
			.getAllDocuments()
			.then(resp => {
				getDocuments(resp.rows || []);

				if (second_call) {
					finishedLoading();
				} else {
					second_call = true;
				}
			})
			.catch(ex => captureError(ex));
	};

	onSupportLogin = () => {
		const { apps, app_id, navigate, getDocuments, captureError } = this.props;

		this.getAPI()
			.getAllDocuments()
			.then(resp => {
				getDocuments(resp.rows || []);
				this.setState({ support_app_id: '' });
				navigate(nextAppPage(apps[app_id]));
			})
			.catch(ex => captureError(ex));
	};

	onSetBypassCode = code => {
		const { email, applyBypassObject, captureError, navigate } = this.props;

		navigate(email ? '/my-account' : '/');

		this.getAPI()
			.getBypassCode(code)
			.then(resp => {
				if (resp.result === 'success') {
					applyBypassObject(resp);
					if (email) this.onLogin();
				} else {
					SENTRY.set_tag('bypass_code', code);
					throw new Error(`error calling getBypassCode`);
				}
			})
			.catch(ex => captureError(new Error(`error calling getBypassCode: ${ex.message}`)));
	};

	onLoadMissingData = key => {
		let {
				onGetData,
				onGetTimezoneDiff,
				onGetPaperAppUrl,
				onGetTerms,
				onGetEssayPrompts,
				onGetMajors,
				captureError
			} = this.props,
			arr_field_methods = {
				system_notifications: 'getAllSystemNotifications',
				campuses: 'getAllCampuses',
				campus_exceptions: 'getAllCampusExceptions',
				specials: 'getAllSpecials',
				career_clusters: 'getAllCareerClusters',
				visa_types: 'getVisas',
				activities: 'getAllActivities',
				waivers: 'getAllWaivers',
				campus_next_steps: 'getCampusNextSteps'
			},
			isInMaintenanceMode = resp => {
				const { maintenance_msg } = this.state;

				if (typeof resp === 'string' && resp.includes('MAINTENANCE|')) {
					if (!maintenance_msg) this.setState({ maintenance_msg: resp.substr('MAINTENANCE|'.length) });
					return true;
				}

				return false;
			};

		if (arr_field_methods[key]) {
			this.getAPI()
				[arr_field_methods[key]]()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetData(key, resp);
				})
				.catch(ex => captureError(ex));
		}

		if (key === 'timezone_diff') {
			this.getAPI()
				.getServerTime()
				.then(resp => {
					let strResponse = resp.replace(/-/g, '/'), // iPhones/iPads can't create a date from the original string
						min_diff = (new Date(strResponse) - new Date()) / 60000,
						hours_diff = Math.round(min_diff / 60, 0);

					if (min_diff / 60 > -0.5 && min_diff / 60 < 0) hours_diff = 0; // keep from saving "-0"

					onGetTimezoneDiff(hours_diff);
				})
				.catch(ex => captureError(ex));
		}

		if (key === 'paper_application_url') {
			this.getAPI()
				.getPaperApplicationURL()
				.then(resp => {
					if (!isInMaintenanceMode(resp) && Array.isArray(resp) && !!resp.length) {
						onGetPaperAppUrl(resp[0].field_application_pdf);
					}
				})
				.catch(ex => captureError(ex));
		}

		if (key === 'terms') {
			this.getAPI()
				.getCampusSpecialTerms()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetTerms('special', resp);
				})
				.catch(ex => captureError(ex));

			this.getAPI()
				.getCampusGradTerms()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetTerms('grad', resp);
				})
				.catch(ex => captureError(ex));

			this.getAPI()
				.getDefaultUndergradTerms()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetTerms('default_undergrad', resp);
				})
				.catch(ex => captureError(ex));

			this.getAPI()
				.getCampusUndergradTerms()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetTerms('campus_undergrad', resp);
				})
				.catch(ex => captureError(ex));
		}

		if (key === 'essay_prompts') {
			this.getAPI()
				.getGlobalEssayPrompts()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetEssayPrompts('global', resp);
				})
				.catch(ex => captureError(ex));

			this.getAPI()
				.getCampusEssayPrompts()
				.then(resp => {
					if (!isInMaintenanceMode(resp)) onGetEssayPrompts('campus_specific', resp);
				})
				.catch(ex => captureError(ex));
		}

		if (key === 'cdrMajors') {
			let _arrs = {
					majors: { 1: [], 2: [], 3: [] },
					sub_majors: { 1: [], 2: [], 3: [] }
				},
				applyApiResponse = (resp, data_type, i) => {
					if (!isInMaintenanceMode(resp)) {
						const valid_resp = Array.isArray(resp) && !!resp.length;

						if (valid_resp) {
							_arrs[data_type][i] = resp;

							if (['1', '2', '3'].every(n => !!_arrs.majors[n].length && !!_arrs.sub_majors[n].length)) {
								onGetMajors(
									_arrs.majors['1'].concat(_arrs.majors['2']).concat(_arrs.majors['3']),
									_arrs.sub_majors['1'].concat(_arrs.sub_majors['2']).concat(_arrs.sub_majors['3'])
								);
							}
						}

						return valid_resp;
					}

					return true; // don't create additional calls during maintenance mode
				};

			for (let i = 1; i < 4; i++) {
				this.getAPI()
					.getMajorsByThirds(i)
					.then(resp_1 => {
						if (!applyApiResponse(resp_1, 'majors', i)) {
							this.getAPI()
								.getMajorsByThirds(i)
								.then(resp_2 => applyApiResponse(resp_2, 'majors', i))
								.catch(ex => captureError(ex));
						}
					})
					.catch(ex => captureError(ex));

				this.getAPI()
					.getSubMajorsByThirds(i)
					.then(resp_1 => {
						if (!applyApiResponse(resp_1, 'sub_majors', i)) {
							this.getAPI()
								.getSubMajorsByThirds(i)
								.then(resp_2 => applyApiResponse(resp_2, 'sub_majors', i));
						}
					})
					.catch(ex => captureError(ex));
			}
		}
	};

	onFetchSupportApps = resp => {
		const { login, setAppId, setApps, terms } = this.props,
			{ support_app_id } = this.state;

		if (resp.result !== 'error' && resp.rows.length && resp.all_apps.length) {
			let app_row = resp.rows[0];

			login(
				{
					id: app_row.user_id,
					email: app_row.email,
					field_first_name: app_row.first_name,
					field_last_name: app_row.last_name,
					field_phone_number: app_row.phone_number,
					support_student_id: app_row.user_id,
					campus_admin: !!resp.campus_admin
				},
				resp.direct_admit_row
			);

			setApps(resp.all_apps || []);
			setAppId(support_app_id, terms);
		}
	};

	onAppIsSaving = () => {
		const { apps, app_id, temp_app_rec, captureError } = this.props;

		if (app_id) {
			this.getAPI()
				.saveApplication(app_id, JSON.stringify(apps[app_id].json_obj))
				.then(resp => {
					const msg = resp?.message || '';

					if (resp?.status !== 'success') {
						throw new Error(msg);
					} else {
						this.handleSaveApplication(resp);
					}
				})
				.catch(ex => {
					captureError(`error calling saveApplication: ${ex.message}`);
				});
		} else {
			this.getAPI()
				.saveApplication('-1', JSON.stringify(temp_app_rec.json_obj))
				.then(resp => {
					const msg = resp?.message || '';

					if (resp?.status !== 'success') {
						throw new Error(msg);
					} else {
						this.handleSaveApplication(resp, resp.app_id);
					}
				})
				.catch(ex => {
					captureError(`error calling saveApplication: ${ex.message}`);
				});
		}
	};

	handleSaveApplication(response, new_app_id) {
		let { handleAppSave, captureError } = this.props,
			_api_error = '',
			in_maintenance = false,
			str_campus_suport = 'Campus administrators and support cannot save applications';

		if (response.status === 'success') {
			if ((response.app_id || '').length !== 13) _api_error = `Ill-formed App ID: ${response.app_id}`;
		} else if (response.status && response.status === 'error') {
			if (response.code) {
				_api_error =
					response.code === 'Campus Support' ? str_campus_suport : `handleSaveApplication: ${response.code}`;
			} else {
				_api_error = 'handleSaveApplication: No Code';
			}
		} else if (response.includes('unknown fetch error')) {
			_api_error = 'handleSaveApplication: unknown fetch error';
		} else if (response.includes('MAINTENANCE|')) {
			in_maintenance = true;
			this.setState({ maintenance_msg: response.substr('MAINTENANCE|'.length) });
		} else {
			_api_error = 'handleSaveApplication: unhandled response';
		}

		if (_api_error?.includes(str_campus_suport)) {
			alert(_api_error);
		} else if (_api_error) {
			captureError(_api_error);
		}

		if (!in_maintenance) {
			if (new_app_id) {
				this.getAPI()
					.getAllApplications()
					.then(resp => {
						const new_app_rec = resp.rows.find(row => row.application_id === new_app_id);
						handleAppSave(_api_error, new_app_rec);
					})
					.catch(ex => captureError(ex));
			} else {
				try {
					handleAppSave(_api_error);
				} catch (ex) {
					captureError(ex);
				}
			}
		}
	}

	render() {
		const { children } = this.props,
			{ support_app_id, maintenance_msg } = this.state;

		if (isIE) return <IEDisabled />;

		if (support_app_id) return null;

		if (maintenance_msg) return <MaintenancePage msg={maintenance_msg} />;

		return (
			<>
				<IdleTimer />
				{children}
			</>
		);
	}
}

const mapStateToProps = state => {
		const { terms, loading_data, essay_prompts } = state.global,
			{
				user_id,
				apps,
				app_id,
				temp_app_rec,
				support_student_id,
				app_is_saving,
				email,
				force_pwd_reset,
				documents,
				campus_admin,
				email_verified
			} = state.user;

		return {
			terms: terms,
			loading_data: loading_data,
			apps: apps,
			user_id: user_id,
			app_id: app_id,
			temp_app_rec: temp_app_rec,
			support_student_id: support_student_id,
			app_is_saving: app_is_saving,
			email: email,
			force_pwd_reset: force_pwd_reset,
			documents: documents,
			campus_admin: campus_admin,
			campus_essay_prompts: essay_prompts.campus_specific,
			force_email_verification: !!user_id && !email_verified && !support_student_id && !campus_admin
		};
	},
	mapDispatchToProps = dispatch => ({
		setCurrentPage: val => dispatch(GlobalActions.setCurrentPage(val)),
		onCheckForStaleData: current_page => dispatch(GlobalActions.onCheckForStaleData(current_page)),
		getDocuments: arr => dispatch(UserActions.getDocuments(arr)),
		setApps: arr => dispatch(UserActions.setApps(arr)),
		setUseTempAppRec: (use_temp_app, unset_addtl) =>
			dispatch(UserActions.setUseTempAppRec(use_temp_app, unset_addtl)),
		handleAppSave: (strError, new_app_rec) => dispatch(UserActions.handleAppSave(strError, new_app_rec)),
		login: (obj, direct_admit) => dispatch(UserActions.login(obj, direct_admit)),
		applyBypassObject: obj => dispatch(UserActions.applyBypassObject(obj)),
		finishedLoading: () => dispatch(UserActions.finishedLoading()),
		setAppId: (id, terms) => dispatch(UserActions.setAppId(id, terms, false)),
		deleteApp: id => dispatch(UserActions.deleteApp(id)),
		captureError: err => dispatch(GlobalActions.captureError(err)),
		onGetData: (str_prop, arr) => dispatch(GlobalActions.onGetData(str_prop, arr)),
		onGetTimezoneDiff: val => dispatch(GlobalActions.onGetTimezoneDiff(val)),
		onGetPaperAppUrl: str => dispatch(GlobalActions.onGetPaperAppUrl(str)),
		onGetTerms: (str_prop, arr) => dispatch(GlobalActions.onGetTerms(str_prop, arr)),
		onGetEssayPrompts: (str_prop, arr) => dispatch(GlobalActions.onGetEssayPrompts(str_prop, arr)),
		onGetMajors: (majors, sub_majors) => dispatch(GlobalActions.onGetMajors(majors, sub_majors)),
		updateApp: obj => dispatch(UserActions.updateApp(obj))
	});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrapper));
